// @font-face {
//   font-family: "Raleway";
//   src: url("../fonts/Raleway_light.woff2") format("woff2"),
//     url("../fonts/Raleway_light.woff") format("woff");
//   font-weight: 300;
// }

// @font-face {
//   font-family: "Raleway";
//   src: url("../fonts/Raleway_regular.woff2") format("woff2"),
//     url("../fonts/Raleway_regular.woff") format("woff");
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Raleway";
//   src: url("../fonts/Raleway_medium.woff2") format("woff2"),
//     url("../fonts/Raleway_medium.woff") format("woff");
//   font-weight: 500;
// }
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
