.popup-comment {
  display: flex;
  flex-direction: column;

  background: linear-gradient(0deg, #e2edff, #e2edff), url(image.png);
  border-radius: 25px;
  max-width: 767px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;

  & button {
    display: none;
  }

  & img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }

  & .name {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: linear-gradient(111.85deg, #549afe -18.25%, #065eff 53.76%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 8px;
  }

  & .comment {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #373737;
    margin-top: 13px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .popup-comment {
    & button {
      display: block;
    }
  }
}

//========================================================================================================================================================

.popup-callback {
  max-width: 520px;
  padding: 33px 74px 42px;
  background: #fff;
  margin: 25px auto;
  border-radius: 15px;

  position: relative;
  border: 1px solid #000;
  @media (max-width: 991px) {
    max-width: calc(100% - 20px);
    padding: 20px 25px;
  }
  // .popup-callback__close

  .mfp-close {
    transform: translate(50%, -50%);
    position: absolute;
    right: 0;
    opacity: 1;
    transition: all 0.3s ease 0s;
    &:hover {
      color: #065eff;
      border-color: #065eff;
    }
    @media (max-width: 991px) {
      transform: translate(25%, -25%);
    }
    top: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #000;
    color: #000;
  }

  // .popup-callback__label

  &__label {
    display: flex;
    align-items: center;
    padding: 8px 18px;
    background: #005bff;
    border-radius: 25px;
    max-width: max-content;
    margin: 0 auto 24px;

    & img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }

    & span {
      font-family: inherit;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-left: 10px;
      display: inline-block;
      margin-top: 2px;
    }
  }

  // .popup-callback__title

  &__title {
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 86.538% */
    margin-bottom: 20px;
    text-align: center;
    color: #000;
    @media (max-width: 991px) {
      font-size: 32px;
      line-height: 1;
    }
  }

  // .popup-callback__subtitle

  &__subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 143.75% */
    color: #000;
    text-align: center;
    margin-bottom: 43px;
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
  }

  // .popup-callback__form

  &__form {
    .form__field--error {
      input {
        border-color: red;
      }
    }
    .form__field--valid {
      input {
        border-color: #005bff;
      }
    }
    label {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 23px; /* 230% */
      display: block;
      margin-bottom: 3px;
      color: #b1bccb;
      padding: 0 23px;
    }
    input {
      border-radius: 123px;
      border: 1.31px solid #000;
      background: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      height: 50px;
      line-height: 23px; /* 143.75% */
      padding: 14px 23px;
      transition: border-color 0.3s ease 0s;
      &:focus {
        border-color: #065eff;
      }

      &::placeholder {
        color: #b1bccb;
      }
    }
  }

  // .popup-callback__item

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  // .popup-callback__dec

  &__dec {
    position: absolute;
    right: 0;
    transform: translate(45%, 35%);
    bottom: 0;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .form__field-error {
    display: none;
  }
}
.input {
}
.button-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  border-radius: 10px;
  background: #065eff;
  padding: 11px 40px;
  transition: all 0.3s ease 0s;
  &:hover {
    background: #f91658;
  }
}
