.opinion {
  position: relative;
  z-index: 1;
  padding-top: 76px;

  &::after {
    position: absolute;
    content: "";
    background: url("../img/blur.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 666px;
    height: 666px;
    right: 0;
    bottom: 0;
    transform: translateY(50%) scale(2);
    border-radius: 50%;
    pointer-events: none;
  }

  & .wrapper {
    background: linear-gradient(111.85deg, #549afe -18.25%, #065eff 53.76%);
    border-radius: 34px;
    padding: 42px 20px;
    position: relative;
    margin-bottom: 95px;
    z-index: 1;
    margin-top: 12px;

    & .svgator {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 234px;
      height: 267px;
      z-index: 1;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: "";
      background: url("../img/opinion-mask.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 100%);
      width: 360px;
      height: 95px;
      pointer-events: none;
    }

    &::before {
      position: absolute;
      content: "";
      background-image: url("../img/main-mask.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 100%;
      height: 100%;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
  }

  & .description {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .mark {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 25px;
      padding: 8px 18px;
      max-width: max-content;

      & span {
        font-family: inherit;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: linear-gradient(111.85deg, #549afe -18.25%, #065eff 53.76%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-top: 2px;
      }

      & img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        object-fit: contain;
      }
    }

    & h2 {
      font-family: inherit;
      font-weight: 500;
      font-size: 58px;
      line-height: 55px;
      color: #ffffff;
      max-width: 850px;
      text-align: center;
      margin-top: 12px;
    }
  }

  & .content {
    display: flex;

    align-items: flex-end;
    justify-content: space-between;
    margin-top: 20px;

    & .wrap {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        & .img {
          max-width: 358px;
          object-fit: contain;
        }

        & .count {
          display: flex;
          flex-direction: column;
          padding: 16px 22px;
          border: 2px solid rgba(255, 255, 255, 0.2);
          border-radius: 23px;
          margin-top: 17px;

          & .decor {
            display: flex;
            align-items: center;

            & div {
              position: relative;

              & img {
                max-width: 120px;
                object-fit: contain;
              }

              & span {
                font-family: inherit;
                font-weight: 400;
                font-size: 12px;
                line-height: 22px;
                color: #196dff;
                position: absolute;
                top: 18px;
                right: 6px;
              }
            }

            & p {
              font-family: inherit;
              font-weight: 500;
              font-size: 16px;
              line-height: 17px;
              color: #ffffff;
              max-width: 150px;
              margin-left: 18px;
            }
          }

          & .text {
            font-family: inherit;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #ffffff;
            max-width: 250px;
          }
        }

        & .audio-list {
          padding: 17px 8px;
          background: #ffffff;
          border-radius: 23px;
          margin-top: 12px;

          & .audio-item {
            display: flex;
            align-items: center;

            & .ava {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }
            picture {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
              aspect-ratio: 1/1;
            }

            & .panel {
              display: flex;
              align-items: center;
              margin-left: 10px;
              margin-right: 10px;

              & .toggle {
                margin: 0 4px;

                & .stop {
                  display: none;
                }

                &.active {
                  & .stop {
                    display: block;
                  }

                  & .play {
                    display: none;
                  }
                }
              }

              & .next {
                & img {
                  transform: rotate(180deg);
                }
              }
            }

            & .indicators {
              display: flex;
              align-items: center;
              height: 23px;

              & span {
                width: 2px;
                height: 7px;
                display: inline-block;
                margin: 0px 4px;
                background: #649bff;

                animation: indicators 0ms -600ms linear infinite alternate;
              }
            }
          }
        }
      }

      &:nth-child(2) {
        margin-right: 20px;
        margin-left: 20px;

        & img {
          max-width: 347px;
          object-fit: contain;
        }
      }

      &:nth-child(3) {
        & .video {
          position: relative;
          width: max-content;

          &::after {
            position: absolute;
            content: "";
            background-image: url("../img/opinion3-decor.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 150px;
            height: 132px;
            top: -65px;
            right: -90px;
          }

          & picture {
            position: relative;
            z-index: 1;
            border-radius: 25px;
            overflow: hidden;
          }

          & img {
            max-width: 197px;
            object-fit: contain;
          }

          & a {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 1;
            transition: 0.1s ease;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        & .img {
          margin-top: 27px;

          & img {
            max-width: 346px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

@keyframes indicators {
  0% {
    opacity: 0.35;
    height: 7px;
  }

  100% {
    opacity: 1;
    height: 23px;
  }
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(1) {
  animation-duration: 474ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(2) {
  animation-duration: 433ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(3) {
  animation-duration: 407ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(4) {
  animation-duration: 458ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(5) {
  animation-duration: 400ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(6) {
  animation-duration: 427ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(7) {
  animation-duration: 441ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(8) {
  animation-duration: 419ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(9) {
  animation-duration: 487ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(10) {
  animation-duration: 442ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(11) {
  animation-duration: 474ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(12) {
  animation-duration: 433ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(13) {
  animation-duration: 407ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(14) {
  animation-duration: 458ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(15) {
  animation-duration: 400ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(16) {
  animation-duration: 474ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(17) {
  animation-duration: 433ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(18) {
  animation-duration: 407ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(19) {
  animation-duration: 458ms;
}

.opinion
  .content
  .wrap:nth-child(1)
  .audio-list
  .audio-item
  .indicators
  span:nth-child(20) {
  animation-duration: 400ms;
}

@media (min-width: 0px) and (max-width: 992px) {
  .opinion .content {
    flex-direction: column;
    align-items: center;
  }

  .opinion .wrapper::before {
    display: none;
  }

  .opinion .content .wrap:nth-child(2) img {
    margin-top: 12px;
  }

  .opinion .content .wrap:nth-child(3) .video {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .opinion {
    margin: 0px 5px;
  }

  .opinion .description .mark img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }

  .opinion .description .mark span {
    font-size: 10px;
    line-height: 100%;
  }

  .opinion .description .mark {
    padding: 5px 10px;
  }

  .opinion .description h2 {
    font-size: 28px;
    line-height: 28px;
    max-width: 300px;
  }

  .opinion .wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .opinion .wrapper::after {
    width: 333px;
    bottom: 12px;
  }

  .opinion
    .content
    .wrap:nth-child(1)
    .audio-list
    .audio-item
    .indicators
    span:nth-child(15),
  .opinion
    .content
    .wrap:nth-child(1)
    .audio-list
    .audio-item
    .indicators
    span:nth-child(16),
  .opinion
    .content
    .wrap:nth-child(1)
    .audio-list
    .audio-item
    .indicators
    span:nth-child(17),
  .opinion
    .content
    .wrap:nth-child(1)
    .audio-list
    .audio-item
    .indicators
    span:nth-child(18) {
    display: none;
  }

  .opinion .content .wrap:nth-child(1) .img {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .opinion .content .wrap:nth-child(1) .count {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .opinion .content .wrap:nth-child(3) .img img {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .opinion .content .wrap:nth-child(1) .count .decor div span {
    font-size: 12px;
    line-height: 22px;
  }
}
