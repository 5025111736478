.appearance {
  padding-top: 150px;
  min-height: 1800px;

  position: relative;

  &::after {
    position: absolute;
    content: "";
    background: url("../img/blur.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 666px;
    height: 666px;
    left: 0;
    top: 50%;
    transform: translateY(-50%) scale(2);
    border-radius: 50%;
    pointer-events: none;
  }

  & .pulse {
    @keyframes pulse {
      0% {
        transform: scale(0.5) translateX(-30px);
        opacity: 0;
      }

      50% {
        opacity: 1;
      }

      100% {
        transform: scale(1.2) translateX(-30px);
        opacity: 0;
      }
    }

    display: block;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(calc(-50% + 20px));
    pointer-events: none;
    z-index: 1;

    width: 853px;
    height: 853px;

    &::after,
    &::before {
      content: "";
      position: absolute;
      border: 1px solid rgba(11, 97, 255, 0.2);
      left: -20px;
      opacity: 0;
      right: -20px;
      top: -20px;
      bottom: -20px;
      border-radius: 50%;
      animation: pulse 2.5s linear infinite;
      pointer-events: none;
    }

    &::after {
      animation-delay: 1.25s;
    }

    @keyframes rotatePlanet {
      0% {
        transform: translate(30px, 30px) rotate(-30deg);
      }

      50% {
        transform: translate(-60px, -60px) rotate(30deg);
      }

      100% {
        transform: translate(30px, 30px) rotate(-30deg);
      }
    }
  }

  & .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    & .mark {
      display: flex;
      align-items: center;
      padding: 8px 18px;
      background: #005bff;
      border-radius: 25px;
      max-width: max-content;
      transition: all 0.3s ease 0s;
      &:hover{
        background: #f91658 ;
        
      }

      & img {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      & span {
        font-family: inherit;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        margin-left: 10px;
        display: inline-block;
        margin-top: 2px;
      }
    }

    & h2 {
      font-family: inherit;
      font-weight: 400;
      font-size: 50px;
      line-height: 54px;
      text-align: center;
      color: #005bff;
      margin-top: 5px;
      max-width: 540px;
    }

    & .subtitle {
      font-family: inherit;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #001628;
      margin-top: 13px;
      max-width: 460px;
    }
  }

  & .roket {
    width: 3967px;
    height: 1316px;
    position: absolute;
    left: 0;
    z-index: 1;
    transition: 0.1s ease;

    &__roket {
      z-index: 2;
      position: relative;
      margin-left: 215px;
    }

    & .pipe {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);

      width: 1150px;
      height: 1918px;
      z-index: 1;
    }

    & .dark {
      position: absolute;
      left: 1510px;
      top: 50%;
      transform: translateY(-50%);
      width: 1380px;
      height: 1375px;
      z-index: 1;
    }

    & .planet {
      width: 257px;
      height: 189px;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 2900px;
      transition: 0.3s ease;
      animation: rotatePlanet 2.5s linear infinite;
    }

    & .stars {
      @keyframes scaleStars {
        0% {
          transform: scale(1.2);
        }

        50% {
          transform: scale(1);
        }

        100% {
          transform: scale(1.3);
        }
      }

      & span {
        position: absolute;
        background: #005bff;
        border-radius: 50%;
        z-index: 1;
        animation: scaleStars 2.5s linear infinite;
        transition: 0.3s ease;

        &:nth-child(1) {
          width: 64px;
          height: 64px;
          top: 0;
          left: 2290px;
          transition-delay: 0.3;
        }

        &:nth-child(2) {
          width: 142px;
          height: 142px;
          bottom: -140px;
          left: 1210px;
          transition-delay: 0.6;
        }

        &:nth-child(3) {
          width: 82px;
          height: 82px;
          bottom: -200px;
          left: 2300px;
          transition-delay: 0.9;
        }
      }
    }

    &__scale {
      transform: scale(0.8) rotate(-5deg);

      &::after {
        position: absolute;
        content: "";
        background: url("../img/blur.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 666px;
        height: 666px;
        left: 0;
        top: 50%;
        transform: translateY(-50%) scale(2);
        border-radius: 50%;
        pointer-events: none;
      }
    }

    // zoom: 0.6;
    top: 200px;
    transform: translate(-100%, 30%);

    & .cards {
      display: flex;
      flex-direction: column;
      margin-left: 365px;

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;

      & ul {
        display: flex;
        align-items: center;

        &:nth-child(2) {
          margin-top: 35px;
          margin-left: 150px;
        }

        & li {
          width: 226px;
          height: 314px;
          background: #ffffff;
          border-radius: 18px;
          padding: 9px;
          overflow: hidden;
          margin-right: 80px;

          &:last-child {
            margin-right: 0;
          }

          & .lining {
            border: 2px solid #000000;
            border-radius: 18px;
            height: 100%;
            picture {
              height: 100%;
            }
            img {
              height: 100%;
            }
          }
        }

        & .video {
          & .lining {
            position: relative;
          }

          & img {
            border-radius: 18px;
            object-fit: cover;
          }

          & a {
            position: absolute;
            top: 8px;
            right: 8px;
            transition: 0.1s ease;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        & .comment {
          & .lining {
            background: #e2edff;
            padding: 16px 13px;

            & .text {
              font-family: inherit;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              color: #373737;
              margin-top: 23px;
            }

            & .more {
              display: inline-block;
              font-family: inherit;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              text-decoration: underline;
              color: #005bff;
              margin-top: 7px;
            }
          }

          & .author {
            display: flex;
            flex-direction: column;

            & .name {
              font-family: inherit;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 18px;
              background: linear-gradient(
                111.85deg,
                #549afe -18.25%,
                #065eff 53.76%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
              margin-top: 8px;
            }

            & img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.mobile-roket {
  display: none;
}

@media (min-width: 0px) and (max-width: 992px) {
  .appearance {
    overflow: hidden;
    min-height: 5758px;
  }

  .appearance .roket {
    display: none;
  }

  .appearance::after {
    display: none;
  }

  .mobile-roket {
    margin-top: 500px;
    margin-bottom: 500px;
    display: flex;
    justify-content: center;
    position: relative;

    & .more {
      display: inline-block;
      font-family: inherit;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      color: #005bff;
      margin-top: 7px;
    }

    &::before {
      position: absolute;
      content: "";
      background: url(../img/blur.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 666px;
      height: 666px;
      left: 0;
      bottom: 0;
      transform: translate(0, 100%) scale(2);
      border-radius: 50%;
      pointer-events: none;
    }

    &::after {
      position: absolute;
      content: "";
      background: url("../img/roket-mobile.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 430px;
      height: 100%;
      top: 480px;
      left: 50%;
      transform: translateX(-50%) scale(2);
    }
  }

  .mobile-roket__list {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    & li {
      width: 245px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 18px;
      position: relative;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }

      & .lining {
        padding: 10px;
        border-radius: 25px;
      }

      &.video {
        picture img {
          height: 300px;
          object-fit: cover;
        }
        & img {
          max-width: 225px;
          object-fit: contain;
          border-radius: 25px;
        }

        & a {
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }

      &.comment {
        & .lining {
          background: #e2edff;
          margin: 10px;
          border-radius: 25px;

          & .text {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #373737;
            margin-top: 23px;
          }
        }

        & .author {
          display: flex;
          flex-direction: column;

          & .name {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            background: linear-gradient(
              111.85deg,
              #549afe -18.25%,
              #065eff 53.76%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin-top: 8px;
          }

          & img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .appearance .description .mark {
    padding: 5px 10px;
  }

  .appearance .description .mark img {
    width: 12px;
    height: 12px;
  }

  .appearance .description .mark span {
    font-size: 10px;
    line-height: 100%;
    margin-left: 5px;
  }

  .appearance .description h2 {
    font-size: 32px;
    line-height: 32px;
    margin-top: 20px;
    max-width: 300px;
  }

  .appearance .description .subtitle {
    font-size: 16px;
    line-height: 24px;
  }

  .appearance .pulse {
    width: 333px;
    height: 333px;
    top: 80px;
  }
}
