body {
  min-width: 375px;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: "Raleway", "Arial", sans-serif;
  color: #111111;
  overflow-x: hidden;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a {
  text-decoration: none;
  display: inline-block;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

svg {
  pointer-events: none;
}

img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

img {
  width: 100%;
}

button,
input,
select,
textarea {
  font: inherit;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 30px;
  margin: 0 auto;
  box-sizing: border-box;
}

@media (min-width: 0px) and (max-width: 767px) {
  .container {
    padding: 0px 5px;
  }
}

/**
 * Simple fade transition,
 */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.js-load-elem {
  transform: translateY(20px);
  transition: 0.6s ease;
  opacity: 0;
  transition-delay: 1s;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}
