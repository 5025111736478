.footer {
    margin-top: 90px;
    margin-bottom: 40px;

    & .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    & .info {
        display: flex;
        align-items: center;

        & p {
            font-family: inherit;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            color: #000B14;
            & a {
                color: #F91155;
            }
            &:nth-child(1){
                max-width: 265px;
            }
            &:nth-child(2){
                max-width: 220px;
                margin-left: 40px;
            }
        }
    }
}


@media(min-width: 0px) and (max-width: 767px) {
    .footer .info{
        flex-direction: column;
        align-items: flex-start;
    }
    .footer .wrapper{
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .footer .info p:nth-child(2){
        margin-left: 0;
        margin-top: 30px;
        max-width: 265px;
    }
    .footer .info p:nth-child(1){
        margin-top: 30px;
    }
    .footer{
        margin-top: 50px;
    }
}