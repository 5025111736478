.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  min-height: 76px;
  background: #fff;

  & .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 20px;
    position: relative;

    & .logo {
      display: flex;
      align-items: center;
    }

    & .burger {
      width: 30px;
      height: 18px;
      display: flex;
      flex-direction: column;
      position: relative;

      & span {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #000000;
        border-radius: 4px;

        transition: 0.2s ease;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 9px;
        }

        &:nth-child(3) {
          top: 18px;
        }
      }

      &.active {
        & span {
          &:nth-child(1) {
            transform: translate(0px, 10px) rotate(-37deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: translate(0px, -8px) rotate(38deg);
          }
        }
      }
    }
  }

  & .menu {
    margin-left: auto;
    margin-right: 50px;
    margin-top: 3px;

    & ul {
      display: flex;

      & li {
        margin-right: 20px;
        transition: 1s ease;
        opacity: 0;
        pointer-events: none;

        &:nth-child(1) {
          transition-delay: 0s;
        }

        &:nth-child(2) {
          transition-delay: 0.3s;
        }

        &:nth-child(3) {
          transition-delay: 0.6s;
        }

        &:last-child {
          margin-right: 0;
        }

        & a {
          font-family: inherit;
          font-style: normal;
          font-weight: 400;
          font-size: 21px;
          line-height: 25px;
          text-align: center;
          color: #005bff;
        }
      }

      &.active {
        & li {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .header .menu ul li a {
    font-size: 13px;
    line-height: 15px;
  }

  .header .menu ul li {
    margin-right: 7px;
  }

  .header .logo {
    width: 70px;
  }

  .header .menu {
    margin-right: auto;
  }

  .header .wrapper .burger {
    transform: scale(0.8);
    margin-top: -3px;
    width: 25px;
  }
}
