.lead {
  position: relative;
  z-index: 1;
  padding-top: 100px;

  &::after {
    position: absolute;
    content: "";
    background: url("../img/blur.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 333px;
    height: 333px;
    right: 0;
    bottom: 0;
    transform: translateY(-100px) scale(3);
    border-radius: 50%;
    z-index: -1;
    pointer-events: none;
  }
  .button-inner {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  & .wrapper {
    position: relative;
  }

  & .fly-decor {
    & img {
      position: absolute;
      z-index: 1;

      &.box {
        width: 160px;
        height: 160px;
        object-fit: contain;

        &:nth-child(1) {
          left: 140px;
          bottom: 101px;
        }

        &:nth-child(2) {
          top: 370px;
          left: 585px;
        }

        &:nth-child(3) {
          top: -775px;
          right: 0;
        }

        &:nth-child(4) {
          bottom: -170px;
          left: 240px;
        }
      }

      &.trube {
        width: 149px;
        height: 220px;
        object-fit: contain;
        right: 0;
        bottom: 80px;
      }
    }
  }

  & .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;

    & h2 {
      font-family: inherit;
      font-weight: 400;
      font-size: 50px;
      line-height: 54px;
      text-align: center;
      color: #005bff;
      max-width: 800px;
    }

    & .subtitle {
      font-family: inherit;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #001628;
      max-width: 460px;
      margin-top: 18px;
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    & .wrap {
      display: flex;
      position: relative;
      z-index: 1;

      &:nth-child(1) {
        & .review {
          display: flex;
          flex-direction: column;
          background: #dce9fa;
          box-shadow: 0px 7px 14px rgba(0, 77, 215, 0.04);
          border-radius: 23px;
          padding: 48px 36px;
          padding-bottom: 32px;
          @media (min-width: 991px) {
            flex: 0 0 320px;
          }

          & .count {
            font-family: inherit;
            font-style: normal;
            font-weight: 300;
            font-size: 66px;
            line-height: 1.2;
            span {
              font-size: 48px;
            }
            background: linear-gradient(
              111.85deg,
              #549afe -18.25%,
              #065eff 53.76%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          & .text {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 27px;
            color: #3a3a3a;
            margin-top: 121px;
            max-width: 250px;
          }
        }

        & .effect {
          display: flex;
          flex-direction: column;
          padding: 46px 60px;
          padding-bottom: 60px;

          background: linear-gradient(
            281.48deg,
            #f91155 1.07%,
            #fa5183 100.11%
          );
          box-shadow: 0px 7px 14px rgba(0, 77, 215, 0.04);
          border-radius: 23px;
          margin-left: 17px;

          & .text {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 1.2;
            color: #fdfeff;
          }

          & .decor {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 54px;
            text-decoration: underline;
            color: #fdfeff;
            opacity: 0.65;
            min-height: 54px;
            position: relative;

            & span {
            }
          }
        }
      }

      &:nth-child(2) {
        margin-top: 20px;

        & .stud {
          background: linear-gradient(
            111.85deg,
            #549afe -18.25%,
            #065eff 53.76%
          );
          box-shadow: 0px 7px 14px rgba(0, 77, 215, 0.04);
          border-radius: 23px;
          padding: 10px;
          max-width: 750px;

          & h3 {
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 54px;
            color: #fdfeff;

            padding: 26px 43px;
          }

          & .card {
            display: flex;
            flex-direction: column;

            background: #fefeff;
            box-shadow: 0px 7px 14px rgba(0, 77, 215, 0.04);
            border-radius: 23px;
            padding: 23px 42px;

            & .from {
              display: flex;
              align-items: center;

              & img {
                width: 40px;
                height: 40px;
                object-fit: cover;
                border-radius: 50%;
              }

              & .name {
                font-family: inherit;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                background: linear-gradient(
                  111.85deg,
                  #549afe -18.25%,
                  #065eff 53.76%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin-left: 17px;
              }
            }

            &__text {
              font-family: inherit;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 24px;
              color: #001628;
              margin-top: 19px;
            }
          }
        }

        & .decor {
          margin-left: auto;
          margin-right: auto;

          & img {
            max-width: 290px;
          }
        }
      }
    }
  }
}

@media (min-width: 0px) and (max-width: 992px) {
  .lead {
    margin-top: -190px;

    & .fly-decor {
      display: none;
    }
  }

  .lead .content .wrap {
    flex-direction: column;
  }

  .lead .content .wrap:nth-child(1) .effect {
    margin-left: 0;
    margin-top: 20px;
  }

  .lead .content .wrap:nth-child(2) .stud {
    max-width: 100%;
  }

  .lead .content .wrap:nth-child(2) .decor img {
    margin-top: 20px;
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .lead .description h2 {
    font-size: 32px;
    line-height: 32px;
    max-width: 300px;
  }

  .lead .content .wrap:nth-child(1) .review {
    padding: 27px 30px;
  }

  .lead .content .wrap:nth-child(1) .review .count {
    font-size: 65px;
    line-height: 100%;
  }

  .lead .content .wrap:nth-child(1) .review .text {
    font-size: 20px;
    line-height: 27px;
    max-width: 250px;
  }

  .lead .content .wrap:nth-child(1) .effect {
    padding: 27px 30px;
  }

  .lead .content .wrap:nth-child(1) .effect .text {
    font-size: 24px;
    line-height: 24px;
  }

  .lead .content .wrap:nth-child(1) .effect .decor {
    font-size: 32px;
    line-height: 32px;
  }

  .lead .content .wrap:nth-child(1) .review .text {
    margin-top: 50px;
  }

  .lead .content .wrap:nth-child(2) .stud h3 {
    padding: 27px 30px;
    font-size: 32px;
    line-height: 32px;
  }

  .lead .content .wrap:nth-child(2) .stud .card {
    padding: 27px 30px;
  }

  .lead {
    margin-top: -360px;
  }
}
